<ng-container
  *ngIf="{
    pipelineRuns: pipelineRuns$ | async,
    loading: pipelineRunsLoading$ | async,
    isPipelineRunDeleting: isPipelineRunDeleting$ | async,
    isPipelineRunProcessingStatusChanging: isPipelineRunProcessingStatusChanging$ | async,
    pipelineRunDetail: pipelineRunDetail$ | async,
    pipelineRunProcessingStatus: pipelineRunProcessingStatus$ | async,
    coverageThresholdConfiguration: coverageThresholdConfiguration$ | async,
    pipelineRunReleaseStatusOperationLoadingState: pipelineRunReleaseStatusOperationLoadingState$ | async,
  } as data"
>
  <div *ngIf="!data.loading && data.pipelineRuns && data.pipelineRuns.length" class="pipeline-detail-list flex flex-col gap-3">
    <div class="input-area flex flex-row justify-between">
      <div class="flex flex-row flex-wrap gap-5 justify-start">
        <div class="flex flex-col">
          <kendo-label class="k-form" text="Pipeline Run">
            <kendo-dropdownlist
              [disabled]="data.isPipelineRunDeleting"
              [value]="selectedPipelineRunId"
              (selectionChange)="pipelineRunSelectionChanged($event)"
              [data]="data.pipelineRuns"
              [listHeight]="400"
              [popupSettings]="{ width: 'auto' }"
              style="min-width: 385px"
              [valuePrimitive]="true"
              data-test-id="pipeline-run-selection"
              fusionFeatureUsageTracker="pipeline-run-selection"
              [virtual]="dropDownVirtualizationSettings"
              textField="pipelineRunKey"
              valueField="id"
            >
              <ng-template kendoDropDownListItemTemplate let-dataItem>
                <div class="overflow-hidden flex flex-col">
                  <div class="flex flex-row gap-2">
                    <strong class="pipeline-run-info-date">{{ dataItem?.startDateTime | date: 'MMM d, y hh:mm:ss a' }}</strong>
                    <div *ngIf="dataItem?.executionStatus?.displayKey" data-test-id="pipeline-run-execution-status-item">
                      <app-status-chip
                        [status]="dataItem?.executionStatus"
                        statusType="execution-status"
                        [showStatusDate]="false"
                        [showTooltip]="false"
                      ></app-status-chip>
                    </div>
                    <div *ngIf="dataItem?.latestReleaseStatusKeyInGroup.key">
                      <app-status-chip
                        [status]="dataItem?.latestReleaseStatusKeyInGroup"
                        statusType="execution-status"
                        [showStatusDate]="false"
                        [showTooltip]="false"
                      ></app-status-chip>
                    </div>
                    <div *ngIf="dataItem?.hasScheduledInfo">
                      <mat-chip class="mat-chip-small" [disableRipple]="true" [disabled]="true">
                        <div class="flex flex-row gap-1 justify-start items-center">
                          <span class="text-overflow">Scheduled Info</span>
                          <fusion-icon name="check" size="S"></fusion-icon>
                        </div>
                      </mat-chip>
                    </div>
                  </div>
                  <span data-test-id="pipeline-run-key" class="text-noWarp-textEllipsis overflow-hidden">{{
                    dataItem?.pipelineRunKey
                  }}</span>
                </div>
              </ng-template>
              <ng-template kendoDropDownListValueTemplate let-dataItem>
                <div id="pipeline-run-dropdown-value-template" class="flex flex-col">
                  <div class="flex flex-row gap-2">
                    <strong class="pipeline-run-info-date">{{
                      data.pipelineRunDetail?.startDateTime | date: 'MMM d, y hh:mm:ss a'
                    }}</strong>
                    <div *ngIf="dataItem?.executionStatus?.displayKey" data-test-id="pipeline-run-execution-status-value">
                      <app-status-chip
                        [status]="dataItem?.executionStatus"
                        statusType="execution-status"
                        [showStatusDate]="false"
                        [showTooltip]="false"
                      ></app-status-chip>
                    </div>
                    <div *ngIf="data.pipelineRunDetail?.latestReleaseStatusKeyInGroup.key">
                      <app-status-chip
                        [status]="data.pipelineRunDetail?.latestReleaseStatusKeyInGroup"
                        statusType="execution-status"
                        [showStatusDate]="false"
                        [showTooltip]="false"
                      ></app-status-chip>
                    </div>
                    <div *ngIf="data.pipelineRunDetail?.hasScheduledInfo">
                      <mat-chip class="mat-chip-small" [disableRipple]="true" [disabled]="true">
                        <div class="flex flex-row gap-1 justify-start items-center">
                          <span class="text-overflow">Scheduled Info</span>
                          <fusion-icon name="check" size="S"></fusion-icon>
                        </div>
                      </mat-chip>
                    </div>
                  </div>
                  <span class="text-noWarp-textEllipsis" data-test-id="pipeline-run-key">{{ data.pipelineRunDetail?.pipelineRunKey }}</span>
                </div>
              </ng-template>
            </kendo-dropdownlist>
          </kendo-label>
        </div>
        <div *ngIf="data.pipelineRunDetail" class="flex justify-start items-center">
          <div
            [fusionTooltip]="getTooltipMessage(deleteButtonTooltipMessage, data.pipelineRunDetail, data.isPipelineRunDeleting)"
            *ngIf="data.pipelineRunDetail.latestReleaseStatusKeyInGroup.key === null"
          >
            <button
              [disabled]="isDeleteButtonDisabled(data.pipelineRunDetail, data.isPipelineRunDeleting)"
              fusionButton
              fusionButtonKind="outline"
              fusionColor="secondary"
              data-test-id="delete-pipeline-run-btn"
              fusionFeatureUsageTracker="delete-pipeline-run-button"
              [fusionFeatureUsageTrackerEvents]="['click']"
              (click)="deletePipelineRun(data.pipelineRunDetail)"
            >
              <app-loading class="inline-loading-spinner" *ngIf="data.isPipelineRunDeleting" [showLoadingText]="false"></app-loading>
              <fusion-icon *ngIf="!data.isPipelineRunDeleting" name="trash"></fusion-icon>
              Delete Pipeline Run
            </button>
          </div>
        </div>
      </div>
      <div *ngIf="data.pipelineRunDetail" class="flex justify-start items-center">
        <div [fusionTooltip]="getTooltipMessage(changeStatusButtonTooltipMessage, data.pipelineRunDetail, data.isPipelineRunDeleting)">
          <button
            [disabled]="isChangeReleaseStatusButtonDisabled(data.isPipelineRunDeleting)"
            fusionButton
            fusionButtonKind="filled"
            fusionColor="primary"
            data-test-id="change-pipeline-run-status-btn"
            fusionFeatureUsageTracker="change-pipeline-run-status-button"
            [fusionFeatureUsageTrackerEvents]="['click']"
            (click)="openChangePipelineRunStatusDialog(data.pipelineRunDetail)"
          >
            <app-loading
              class="inline-loading-spinner"
              *ngIf="data.pipelineRunReleaseStatusOperationLoadingState === 'loading'"
              [showLoadingText]="false"
            ></app-loading>
            <fusion-icon *ngIf="data.pipelineRunReleaseStatusOperationLoadingState !== 'loading'" name="pen"></fusion-icon>
            Change Current State
          </button>
        </div>
      </div>
    </div>
    <div class="flex flex-col gap-1 pipeline-run-info">
      <div *ngIf="data.pipelineRunDetail?.executionStatus?.displayKey" class="flex flex-row gap-1">
        <span class="title">Pipeline Execution State:&nbsp;</span>
        <span>{{ data.pipelineRunDetail?.executionStatus?.displayKey }}</span>
        <span *ngIf="data.pipelineRunDetail?.executionStatusDate"
          >({{ data.pipelineRunDetail?.executionStatusDate | date: 'MMM d, y hh:mm:ss a' }})</span
        >
        <a *ngIf="data.pipelineRunDetail?.buildUrl" [href]="data.pipelineRunDetail?.buildUrl" target="_blank" rel="noreferrer roopener">
          <i class="fi fi-link-external external-link-icon"></i>
        </a>
      </div>
      <div class="flex flex-row gap-1">
        <span class="title">Release State In Selected (Micro)Delivery:&nbsp;</span>
        <span>{{ data.pipelineRunDetail?.latestReleaseStatusKeyInGroup.displayKey ?? 'No state defined for run' }}</span>
        <span *ngIf="data.pipelineRunDetail?.latestReleaseStatusCreatedAtInGroup"
          >({{ data.pipelineRunDetail?.latestReleaseStatusCreatedAtInGroup | date: 'MMM d, y hh:mm:ss a' }})</span
        >
      </div>
      <div *ngIf="data.pipelineRunDetail?.pipelineRunModeId === 2" class="flex flex-row gap-1">
        <span class="text-noWarp-textEllipsis title">Related Scheduled Run:&nbsp;</span>
        <span
          [class.error]="data.pipelineRunDetail?.isRelatedPipelineRunDeprecated"
          [class.warning]="!data.pipelineRunDetail?.relatedPipelineRunKey"
          class="scheduled-run-deprecated-text flex flex-row gap-1"
          [fusionTooltip]="deprecatedPipelineRun"
          fusionTooltipPosition="bottom-right"
          [fusionTooltipDisabled]="!data.pipelineRunDetail?.isRelatedPipelineRunDeprecated"
          data-test-id="related-scheduled-run-information"
        >
          <span *ngIf="data.pipelineRunDetail?.relatedPipelineRunKey">
            {{ data.pipelineRunDetail?.relatedPipelineRunKey }}
          </span>
          <span *ngIf="data.pipelineRunDetail.relatedStartDateTime">
            ({{ data.pipelineRunDetail.relatedStartDateTime | date: 'MMM d, y hh:mm:ss a' }})</span
          >
          <span *ngIf="!data.pipelineRunDetail?.relatedPipelineRunKey">No related scheduled run found.</span>
          <i
            class="fi fi-exclamation-triangle warning-icon"
            [class.warning]="!data.pipelineRunDetail?.relatedPipelineRunKey"
            [class.error]="data.pipelineRunDetail?.isRelatedPipelineRunDeprecated"
            *ngIf="data.pipelineRunDetail?.isRelatedPipelineRunDeprecated || !data.pipelineRunDetail?.relatedPipelineRunKey"
          ></i>
          <ng-template #deprecatedPipelineRun>
            <div [innerHTML]="'icon-tooltip.show-pipeline-deprecated' | translate"></div>
          </ng-template>
        </span>
      </div>
    </div>
    <div *ngIf="!isRunInDropdown(data.pipelineRuns, data.pipelineRunDetail?.id)">
      <div class="warning-box">
        <div class="flex justify-start items-center">
          <fusion-icon name="exclamation-triangle-outline" class="icon-warn"></fusion-icon>
          <span>
            Pipeline Run <b>{{ data.pipelineRunDetail.pipelineRunKey }}</b> has not been executed during the
            <a href="https://wiki.one.int.sap/wiki/x/Sh7wig" rel="noreferrer noopener" target="_blank"
              ><span>active timespan</span><fusion-icon name="link-external" size="S"></fusion-icon
            ></a>
            of the selected (Micro-)Delivery. Therefore it is not part of the
            <a href="https://wiki.one.int.sap/wiki/x/H4HKj" rel="noreferrer noopener" target="_blank"
              ><span>Pipeline Run selection</span><fusion-icon name="link-external" size="S"></fusion-icon></a
            >.
          </span>
        </div>
      </div>
    </div>
    <app-processing-status-warning-box
      *ngIf="isProcessingStatusWarningVisible(data.pipelineRunProcessingStatus)"
      [restoreButtonVisible]="isArchived(data.pipelineRunProcessingStatus)"
      [loading]="data.isPipelineRunProcessingStatusChanging"
      [singleRun]="true"
      (restore)="restorePipelineRun(data.pipelineRunDetail)"
    >
      <ng-container warningMessage
        >Pipeline Run "{{ data.pipelineRunDetail?.pipelineRunKey }}" is archived, which means that the displayed information is
        incomplete.<br />
      </ng-container>
      <ng-container noButtonWarningMessage
        >Pipeline Run "{{ data.pipelineRunDetail?.pipelineRunKey }}" is currently
        {{ data.pipelineRunProcessingStatus?.processingStatusName }}, which means that the displayed information is incomplete. <br />
      </ng-container>
    </app-processing-status-warning-box>
    <div *ngIf="selectedPipelineRunId" class="grid grid-cols-auto-300 grid-flow-row grid-rows-[125px] gap-2.5">
      <app-list-item-kpis-documents
        [pipelineId]="pipelineId"
        [pipelineRunId]="selectedPipelineRunId"
        class="row-span-2"
      ></app-list-item-kpis-documents>

      <app-list-item-kpis-fc2
        [fusionFeatureUsageTrackerEvents]="['mouseover', 'click']"
        [pipelineRunId]="selectedPipelineRunId"
        fusionFeatureUsageTracker="kpi-pipeline-run-requirements"
        data-test-id="pipeline-fc-2-tile"
        class="row-span-2"
      >
      </app-list-item-kpis-fc2>

      <app-list-item-kpis-regression
        *ngIf="isComponentMappingActive$ | async"
        [fusionFeatureUsageTrackerEvents]="['mouseover', 'click']"
        [pipelineRunId]="selectedPipelineRunId"
        [pipelineId]="pipeline.pipelineId"
        fusionFeatureUsageTracker="kpi-pipeline-run-regression"
        data-test-id="pipeline-regression-tile"
        class="row-span-2"
      >
      </app-list-item-kpis-regression>

      <app-list-item-kpis-requirements
        [pipelineRunId]="selectedPipelineRunId"
        data-test-id="pipeline-requirements-tile"
        class="row-span-2"
      ></app-list-item-kpis-requirements>

      <app-list-item-kpis-fc3
        [currentPipelineRunId]="selectedPipelineRunId"
        fusionFeatureUsageTracker="kpi-pipeline-run-fc3"
        data-test-id="pipeline-fc-3-tile"
        class="row-span-2"
      >
      </app-list-item-kpis-fc3>
      <ng-container *ngIf="(isCoverageLoading$ | async) === false">
        <app-list-item-kpis-coverage
          *ngIf="pipelineRunCoverageData$ | async"
          fusionFeatureUsageTracker="kpi-pipeline-run-coverage"
          data-test-id="pipeline-cobertura-jacoco-coverage-tile"
          [pipelineRunId]="selectedPipelineRunId"
          [fusionFeatureUsageTrackerEvents]="['mouseover', 'click']"
          [threshold]="getCoverageThresholdByType(-1, data.coverageThresholdConfiguration)"
          class="row-span-2"
        >
        </app-list-item-kpis-coverage>
      </ng-container>

      <app-list-item-kpis-sonarqube-coverage
        *ngIf="hasPipelineRunSonarQubeData$ | async"
        [pipelineRunId]="selectedPipelineRunId"
        [threshold]="getCoverageThresholdByType(-1, data.coverageThresholdConfiguration)"
        fusionFeatureUsageTracker="kpi-pipeline-run-sonarqube-coverage"
        [fusionFeatureUsageTrackerEvents]="['mouseover', 'click']"
        data-test-id="pipeline-sonarqube-coverage-tile"
        class="row-span-2"
      >
      </app-list-item-kpis-sonarqube-coverage>

      <app-list-item-kpis-fc1
        [currentPipelineRunId]="selectedPipelineRunId"
        fusionFeatureUsageTracker="kpi-pipeline-run-fc1"
        data-test-id="pipeline-fc-1-tile"
        class="row-span-2"
      >
      </app-list-item-kpis-fc1>

      <ng-container *ngIf="(pipelineRunAtcLoading$ | async) === false">
        <app-list-item-kpis-atc
          *ngIf="pipelineRunAtcInfo$ | async"
          [pipelineRunId]="selectedPipelineRunId"
          [fusionFeatureUsageTrackerEvents]="['mouseover', 'click']"
          data-test-id="pipeline-atc-check-tile"
          class="row-span-2"
        >
        </app-list-item-kpis-atc>
      </ng-container>

      <app-list-item-kpis-ip-scan
        [currentPipelineRunId]="selectedPipelineRunId"
        fusionFeatureUsageTracker="kpi-pipeline-run-ip-scan"
        data-test-id="pipeline-ip-scan-tile"
        class="row-span-2"
      >
      </app-list-item-kpis-ip-scan>

      <app-list-item-kpis-slc25
        [currentPipelineRunId]="selectedPipelineRunId"
        fusionFeatureUsageTracker="kpi-pipeline-run-slc25"
        data-test-id="pipeline-slc-25-tile"
        class="row-span-2"
      >
      </app-list-item-kpis-slc25>
      <ng-container *ngIf="(fosstarsLoading$ | async) === false">
        <app-list-item-kpis-fosstar
          fusionFeatureUsageTracker="kpi-group-fosstar"
          data-test-id="pipeline-fosstars-tile"
          *ngIf="fosstarsData$ | async"
          [currentPipelineRunId]="selectedPipelineRunId"
          class="row-span-2"
        >
        </app-list-item-kpis-fosstar>
      </ng-container>

      <ng-container *ngIf="(malwarescanLoading$ | async) === false">
        <app-list-item-kpis-malwarescan
          *ngIf="malwarescanData$ | async"
          [currentPipelineRunId]="selectedPipelineRunId"
          fusionFeatureUsageTracker="kpi-pipeline-run-malwarescan"
          data-test-id="pipeline-malwarescan-tile"
          class="row-span-2"
        >
        </app-list-item-kpis-malwarescan>
      </ng-container>

      <ng-container *ngIf="(apiMetadataValidatorLoading$ | async) === false">
        <app-list-item-kpis-api-metadata-validator
          *ngIf="apiMetadataValidatorData$ | async"
          [currentPipelineRunId]="selectedPipelineRunId"
          fusionFeatureUsageTracker="kpi-pipeline-run-api-metadata-validator"
          data-test-id="pipeline-api-metadata-validator-tile"
          class="row-span-2"
        >
        </app-list-item-kpis-api-metadata-validator>
      </ng-container>

      <ng-container *ngIf="(centralPolicyResultsLoading$ | async) === false">
        <app-list-item-kpis-policy-results
          *ngIf="centralPolicyResultsData$ | async"
          [currentPipelineRunId]="selectedPipelineRunId"
          fusionFeatureUsageTracker="kpi-pipeline-run-central-policy-results"
          data-test-id="pipeline-policy-results-tile"
        >
        </app-list-item-kpis-policy-results>
      </ng-container>
      <ng-container *ngIf="(customPolicyResultsLoading$ | async) === false">
        <app-list-item-kpis-policy-results
          *ngIf="customPolicyResultsData$ | async"
          [isCustomPolicyResults]="true"
          [currentPipelineRunId]="selectedPipelineRunId"
          fusionFeatureUsageTracker="kpi-pipeline-run-custom-policy-results"
          data-test-id="pipeline-custom-policy-results-tile"
        >
        </app-list-item-kpis-policy-results>
      </ng-container>
    </div>
  </div>
  <div
    *ngIf="
      !data.loading &&
      (!data.pipelineRuns || !data.pipelineRuns.length) &&
      this.deliveryProcessingStatus === DeliveryProcessingStatus.ACTIVE
    "
  >
    <h4>{{ 'connected-pipelines.pipeline-details.no-pipeline-runs.general' | translate }}</h4>
  </div>
  <div
    *ngIf="
      !data.loading && (!data.pipelineRuns || !data.pipelineRuns.length) && this.deliveryProcessingStatus === DeliveryProcessingStatus.NEW
    "
  >
    <h4>{{ 'connected-pipelines.pipeline-details.no-pipeline-runs.status-new' | translate }}</h4>
  </div>
  <div
    *ngIf="
      !data.loading &&
      (!data.pipelineRuns || !data.pipelineRuns.length) &&
      this.deliveryProcessingStatus !== DeliveryProcessingStatus.NEW &&
      this.deliveryProcessingStatus !== DeliveryProcessingStatus.ACTIVE
    "
  >
    <h4>{{ 'connected-pipelines.pipeline-details.no-pipeline-runs.status-released' | translate }}</h4>
  </div>
  <ng-template #confirm>
    <div class="flex flex-col justify-items-center gap-2.5">
      <div>
        <span>Are you sure you want to delete the following pipeline run in all linked deliveries:</span>
        <p>
          <strong>{{ data.pipelineRunDetail?.pipelineRunKey || '&mdash;' }}</strong>
        </p>
        <span>This operation can not be undone.</span>
      </div>
      <span class="flex flex-grow"></span>
      <div class="note flex flex-row justify-items-center gap-2.5">
        <fusion-icon id="confirm-dialog-warning-icon" name="exclamation-triangle"></fusion-icon>
        <span>
          Please ensure that the pipeline run you want to delete is already finished.<br />
          Otherwise it could lead to issues.
        </span>
      </div>
    </div>
  </ng-template>

  <div *ngIf="data.loading" class="flex justify-start items-center">
    <app-loading></app-loading>
  </div>
</ng-container>
