<ng-container
  *ngIf="{
    loading: isLoading$ | async,
    loadingError: isLoadingError$ | async,
    loadingNotFound: isLoadingNotFound$ | async,
    complianceInformation: fc1complianceInfo$ | async,
  } as data"
>
  <app-list-item-kpis [flag]="'kpi.flag.beta' | translate" [isLoading]="data.loading" [title]="'kpi.tile.fc1.title' | translate">
    <div class="flex flex-col gap-3.5 w-full" kpi-tile-content>
      <div *ngIf="currentPipelineRunId" class="flex flex-grow" kpi-tile-content data-test-id="fc-1-check-result-text">
        <div *ngIf="data.complianceInformation?.sonarQube as sonarQube" class="flex flex-col h-full w-full">
          <div class="flex flex-row justify-between items-center">
            <a *ngIf="sonarQube.blockerUrl" [href]="sonarQube.blockerUrl" target="_blank" rel="noreferrer roopener"
              ><span>Blocker</span>
              <fusion-icon name="link-external" size="S"></fusion-icon>
            </a>
            <span *ngIf="!sonarQube.blockerUrl" class="label-value">Blocker</span>
            <span class="label-value">{{ sonarQube.blocker }}</span>
          </div>
          <mat-divider></mat-divider>
          <div class="flex flex-row justify-between items-center">
            <a *ngIf="sonarQube.criticalUrl" [href]="sonarQube.criticalUrl" target="_blank" rel="noreferrer roopener"
              ><span>Critical</span>
              <fusion-icon name="link-external" size="S"></fusion-icon>
            </a>
            <span *ngIf="!sonarQube.criticalUrl" class="label-value">Critical</span>
            <span class="label-value">{{ sonarQube.critical }}</span>
          </div>
          <mat-divider></mat-divider>
          <div class="flex flex-row justify-between items-center">
            <a *ngIf="sonarQube.majorUrl" [href]="sonarQube.majorUrl" target="_blank" rel="noreferrer roopener"
              ><span>Major</span>
              <fusion-icon name="link-external" size="S"></fusion-icon>
            </a>
            <span *ngIf="!sonarQube.majorUrl" class="label-value">Major</span>
            <span class="label-value">{{ sonarQube.major }}</span>
          </div>
        </div>
      </div>
      <app-compliance-status-label
        [complianceInformation]="data.complianceInformation"
        [loadingState]="{
          loadingError: data.loadingError,
          loadingNotFound: data.loadingNotFound,
        }"
        [noFilesUploadedCondition]="!data.complianceInformation?.sonarQube"
        (labelClicked)="onSummaryStatusClicked()"
        (reloadButtonClicked)="reloadInfo()"
      ></app-compliance-status-label>
    </div>

    <div kpi-tile-footer>
      <span fusionFeatureUsageTracker="kpi-open-fc1-wiki">
        According to the checked
        <a
          href="https://github.tools.sap/P4TEAM/cumulus-compliance-configuration/blob/master/FC-1.yml"
          target="_blank"
          rel="noopener noreferrer"
          class="clickable"
        >
          <span>FC-1 compliance <br />rules</span>
          <fusion-icon id="fc1LinkIcon" name="link-external" size="S"></fusion-icon> </a
        >.
      </span>
    </div>
  </app-list-item-kpis>
</ng-container>
