<div *ngIf="delivery$ | async as delivery" class="grid grid-cols-[repeat(auto-fit,300px)] gap-[20px]">
  <app-list-item-kpis-fc2
    fusionFeatureUsageTracker="kpi-group-requirements"
    [readOnly]="readOnly"
    data-test-id="aggregated-fc-2-tile"
    class="row-span-2"
  ></app-list-item-kpis-fc2>

  <app-list-item-kpis-fc3
    fusionFeatureUsageTracker="kpi-group-fc3"
    [groupId]="delivery?.id"
    [groupComplianceMode]="groupComplianceMode"
    data-test-id="aggregated-fc-3-tile"
    class="row-span-2"
  >
  </app-list-item-kpis-fc3>

  <ng-container *ngIf="(coverageGroupSummaryIsLoading$ | async) === false">
    <app-list-item-kpis-coverage
      *ngIf="coverageData$ | async"
      [groupId]="delivery?.id"
      [groupComplianceMode]="groupComplianceMode"
      fusionFeatureUsageTracker="kpi-group-coverage"
      [fusionFeatureUsageTrackerEvents]="['mouseover', 'click']"
      data-test-id="aggregated-cobertura-jacoco-coverage-tile"
      class="row-span-2"
    >
    </app-list-item-kpis-coverage>
  </ng-container>

  <ng-container *ngIf="(sonarQubeCoverageGroupSummaryIsLoading$ | async) === false">
    <app-list-item-kpis-sonarqube-coverage
      *ngIf="sonarQubeCoverageData$ | async"
      [groupId]="delivery?.id"
      [groupComplianceMode]="groupComplianceMode"
      fusionFeatureUsageTracker="kpi-group-sonarQube-coverage-summary"
      data-test-id="aggregated-sonarqube-coverage-tile"
      class="row-span-2"
    ></app-list-item-kpis-sonarqube-coverage>
  </ng-container>

  <app-list-item-kpis-fc1
    fusionFeatureUsageTracker="kpi-group-fc1"
    [groupId]="delivery?.id"
    [groupComplianceMode]="groupComplianceMode"
    data-test-id="aggregated-fc-1-tile"
    class="row-span-2"
  >
  </app-list-item-kpis-fc1>

  <ng-container *ngIf="(atcLoading$ | async) === false">
    <app-list-item-kpis-atc
      *ngIf="atcData$ | async"
      fusionFeatureUsageTracker="kpi-group-atc"
      [groupId]="delivery?.id"
      [groupComplianceMode]="groupComplianceMode"
      data-test-id="aggregated-atc-tile"
      class="row-span-2"
    ></app-list-item-kpis-atc>
  </ng-container>

  <app-list-item-kpis-ip-scan
    fusionFeatureUsageTracker="kpi-group-ip-scan"
    [groupId]="delivery?.id"
    [groupComplianceMode]="groupComplianceMode"
    data-test-id="aggregated-ip-scan-tile"
    class="row-span-2"
  >
  </app-list-item-kpis-ip-scan>

  <app-list-item-kpis-slc25
    fusionFeatureUsageTracker="kpi-group-slc25"
    [groupId]="delivery?.id"
    [groupComplianceMode]="groupComplianceMode"
    data-test-id="aggregated-slc-25-tile"
    class="row-span-2"
  >
  </app-list-item-kpis-slc25>

  <ng-container *ngIf="(fosstarsLoading$ | async) === false">
    <app-list-item-kpis-fosstar
      *ngIf="fosstarsData$ | async"
      fusionFeatureUsageTracker="kpi-group-fosstar"
      [groupId]="delivery?.id"
      [groupComplianceMode]="groupComplianceMode"
      data-test-id="aggregated-fosstar-tile"
      class="row-span-2"
    >
    </app-list-item-kpis-fosstar>
  </ng-container>

  <ng-container *ngIf="(malwarescanLoading$ | async) === false">
    <app-list-item-kpis-malwarescan
      *ngIf="malwarescanData$ | async"
      fusionFeatureUsageTracker="kpi-group-malwarescan"
      [groupId]="delivery?.id"
      [groupComplianceMode]="groupComplianceMode"
      data-test-id="aggregated-malwarescan-tile"
      class="row-span-2"
    >
    </app-list-item-kpis-malwarescan>
  </ng-container>

  <ng-container *ngIf="(apiMetadataValidatorLoading$ | async) === false">
    <app-list-item-kpis-api-metadata-validator
      *ngIf="apiMetadataValidatorData$ | async"
      fusionFeatureUsageTracker="kpi-group-api-metadata-validator"
      [groupId]="delivery?.id"
      [groupComplianceMode]="groupComplianceMode"
      data-test-id="aggregated-api-metadata-validator-tile"
      class="row-span-2"
    >
    </app-list-item-kpis-api-metadata-validator>
  </ng-container>
  <ng-container *ngIf="(centralPolicyResultsLoading$ | async) === false">
    <app-list-item-kpis-policy-result-aggregation
      *ngIf="centralPolicyResultsData$ | async"
      fusionFeatureUsageTracker="kpis-policy-result-aggregation"
      [groupId]="delivery?.id"
      [groupComplianceMode]="groupComplianceMode"
      data-test-id="aggregated-policy-results-tile"
    >
    </app-list-item-kpis-policy-result-aggregation>
  </ng-container>
  <ng-container *ngIf="(centralPolicyUsageLoading$ | async) === false">
    <app-list-item-kpis-policy-usage-aggregation
      *ngIf="centralPolicyUsageData$ | async"
      fusionFeatureUsageTracker="kpis-policy-usage-aggregation"
      [groupId]="delivery?.id"
      [groupComplianceMode]="groupComplianceMode"
      data-test-id="aggregated-central-policy-usage-tile"
    >
    </app-list-item-kpis-policy-usage-aggregation>
  </ng-container>

  <ng-container *ngIf="(customPolicyResultsLoading$ | async) === false">
    <app-list-item-kpis-policy-result-aggregation
      *ngIf="customPolicyResultsData$ | async"
      [isCustomPolicyResults]="true"
      fusionFeatureUsageTracker="kpis-custom-policy-result-aggregation"
      [groupId]="delivery?.id"
      [groupComplianceMode]="groupComplianceMode"
      data-test-id="aggregated-custom-policy-results-tile"
    >
    </app-list-item-kpis-policy-result-aggregation>
  </ng-container>
  <ng-container *ngIf="(customPolicyUsageLoading$ | async) === false">
    <app-list-item-kpis-policy-usage-aggregation
      *ngIf="customPolicyUsageData$ | async"
      [isCustomPolicyUsage]="true"
      fusionFeatureUsageTracker="kpis-custom-policy-usage-aggregation"
      [groupId]="delivery?.id"
      [groupComplianceMode]="groupComplianceMode"
      data-test-id="aggregated-custom-policy-usage-tile"
    >
    </app-list-item-kpis-policy-usage-aggregation>
  </ng-container>
</div>
